import { Button, ButtonProps } from '@mui/material';
import React from 'react';

interface ButtonCustomProps extends ButtonProps { }

const ButtonCustom: React.FC<ButtonCustomProps> = ({ children, size, ...props }) => {
	const getSizeStyles = (size: ButtonProps['size']) => {
		switch (size) {
			case 'small':
				return {
					width: '130px',
					height: '36px',
					padding: '0px',
					fontSize: '14px',
				};
			case 'large':
				return {
					width: '190px',
					height: '60px',
					fontSize: '18px',
				};
			case 'medium':
			default:
				return {
					width: '170px',
					height: '50px',
					fontSize: '16px',
				};
		}
	};

	return (
		<Button
			{...props}
			sx={{
				...getSizeStyles(size),
				boxShadow: 'none', // Supprime l'ombre
				'&:hover': {
					boxShadow: 'none', // Supprime l'ombre au survol
				},
				...props.sx,
			}}
		>
			{children}
		</Button>
	);
};

export default ButtonCustom;