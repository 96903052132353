import parse from 'html-react-parser';
import { t } from 'i18next';
import { IErrorObject, IErrorResponse, Order } from './contracts';

export const PublicMenus = [
    {
        name: 'MENU_CONTACT',
        path: '/contact',
        footerOrder: 5,
        order: 5,
    },
    {
        name: 'MENU_HOW_IT_WORKS',
        path: '/how-it-works',
        footerOrder: 2,
        order: 4,
    },
    {
        name: 'MENU_PRICING',
        path: '/pricing',
        order: 9,
        footerOrder: 3,
        removeFromHeader: true,
    },
    {
        name: 'TERMS_OF_USE',
        path: 'https://designbuilder.co.il/policy/',
        order: 7,
        external: true,
        footerOrder: 4,
        removeFromHeader: true,
    },
    {
        name: 'DESIGN_BUILDER',
        path: 'https://designbuilder.co.il/',
        order: 8,
        footerOrder: 1,
        external: true,
        removeFromHeader: true,
    },
]

export const PrivateMenus = [
    {
        name: 'MENU_BUSINESS',
        path: '/edit/business',
        order: 3,
    },
    {
        name: 'MENU_EDIT_USER',
        path: '/edit/user',
        order: 2,
    },
    {
        name: 'MENU_DASHBOARD',
        path: '/',
        order: 1,
    },
]

export const AdminMenus = [
    {
        name: 'MENU_ADMIN_TICKETS',
        path: '/admin/tickets',
    },
    {
        name: 'MENU_ADMIN_DASHBOARD',
        path: '/admin/dashboard',
    },
]

export function getAuthorization({ auth, user, organizations }: any) {
    let authorization
    if (!user || !user.organizationId) {
        return null
    }
    const organization = organizations[user.organizationId]
    if (!organization) {
        return null
    }

    const members = organization.members || []
    for (let i = 0; i < members.length; i++) {
        let member = members[i]
        if (auth && user && member.id === auth.id) {
            authorization = member.authorization
            break
        }
    }
    return authorization
}

export const RemoveAllLoggedInUsersData = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('organizations')
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('language')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('organisationId')
    sessionStorage.removeItem('organizationId')
}

export function monthDiff(d1: any, d2: any) {
    let fromDate = d1
    let toDate = d2
    if (typeof fromDate === 'string') {
        fromDate = new Date(d1)
    }
    if (typeof toDate === 'string') {
        toDate = new Date(d2)
    }
    let months
    months = (toDate.getFullYear() - fromDate.getFullYear()) * 12
    months -= fromDate.getMonth() + 1
    months += toDate.getMonth()
    return months <= 0 ? 0 : months
}

export function calcCreditedPoints(transaction: any) {
    if (transaction.monthly === true) {
        let diffInMonths = monthDiff(transaction.createdAt, new Date())
        return (diffInMonths + 1) * (transaction.points / 12)
    } else {
        return transaction.points
    }
}

export function calcTotalAccountPoints(organization: any) {
    const { transactions } = organization

    let list = []
    for (let property in transactions) {
        if (transactions.hasOwnProperty(property)) {
            list.push(transactions[property])
        }
    }

    let points = 0
    list &&
        list
            .filter((a) => a.status === 'success')
            .sort((a, b) => b.createdAt - a.createdAt)
            .forEach((transaction) => {
                if (
                    transaction.type === 'CREDIT' &&
                    transaction.status === 'success'
                ) {
                    points += calcCreditedPoints(transaction)
                } else if (
                    transaction.status === 'success' &&
                    transaction.type === 'DEBIT'
                ) {
                    if (!transaction.usedPowerCredits) {
                        points -= transaction.points
                    }
                }
            })
    return points
}

export const totalDebitPoints = (organization: any) => {
    const { transactions } = organization
    let totalDebits = 0

    for (let property in transactions) {
        if (transactions.hasOwnProperty(property)) {
            const transaction = transactions[property]
            if (
                transaction.type === 'DEBIT' &&
                transaction.status === 'success'
            ) {
                totalDebits += transaction.points || 0
            }
        }
    }

    return totalDebits
}

export const GetUserOrganization = (organisation: any, user: any) => {
    return organisation && user && user.organizationId
        ? organisation[`${user.organizationId}`]
        : ''
}

export const ellipsis = (value: any, size: any) => {
    return value && value.length > size
        ? value.substring(0, size - 3) + '...'
        : value || ''
}

export const getErrorHtmlContent = (error: IErrorResponse) => {
    let errorHtml = []

    if (error.ServerErrorCode) {
        errorHtml = error.ServerErrorCode.map((code) => generateErrorHTML(code))
    } else {
        errorHtml = [`<span>${t('GENERAL_ERROR')}</span>`]
    }
    return parse(`<div class="errorsNotistack"> ${errorHtml.join('')} </div>`)
}

const generateErrorHTML = (errorObj: IErrorObject) => {
    return `<span class="errorSpan">${
        errorObj?.field?.toLowerCase() !== 'message'
            ? errorObj?.field?.replace('_', ' ') + ':'
            : ''
    } ${t(errorObj.code)}</span>\n`
}

export const processTextWithLinks = (text: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|\b([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?/g;

    // Diviser le texte en lignes
    const lines = text.split('\n');

    // Traiter chaque ligne séparément
    const processedLines = lines.map(line => {
        return line.replace(urlRegex, (match) => {
            let url = match;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
            }
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
        });
    });

    // Rejoindre les lignes avec des balises <br> pour les sauts de ligne
    return processedLines.join('<br>');
};

export const getRevisionErrorHtmlContent = (errorMessages: string[]) => {
    let errorHtml = []
    if (errorMessages.length > 0) {
        errorHtml = errorMessages.map((message) => generateRevisionErrorHTML(message))
    } else {
        errorHtml = [`<span>${t('GENERAL_ERROR')}</span>`]
    }
    return parse(`<div class="errorsNotistack"> ${errorHtml.join('<div></div>')} </div>`)
}

const generateRevisionErrorHTML = (errorObj: string) => {
    // Regex améliorée pour capturer les URLs avec ou sans le protocole
    const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|\b([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?/g;

    const messageWithLinks = t(errorObj).replace(
        linkRegex,
        (match) => {
            let url = match;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'https://' + url;
            }
            url = url.replace(/([^:]\/)\/+/g, "$1");
            return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline; color:#0F65EF;">${match}</a>`;
        }
    );
    return messageWithLinks;
}

export function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const aValue = a[orderBy]
    const bValue = b[orderBy]
    if (orderBy === 'credit') {
        const totalPointsA = calcTotalAccountPoints(a)
        const totalPointsB = calcTotalAccountPoints(b)
        return totalPointsB - totalPointsA
    }
    if (orderBy === 'purchased') {
        const totalPointsA = calcTotalAccountPoints(a) + totalDebitPoints(a)
        const totalPointsB = calcTotalAccountPoints(b) + totalDebitPoints(b)
        return totalPointsB - totalPointsA
    }
    if (aValue == null && bValue == null) {
        return 0
    }
    if (aValue == null) {
        return 1
    }
    if (bValue == null) {
        return -1
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
        return (bValue as string).localeCompare(aValue as string)
    }
    if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return (bValue ? 1 : 0) - (aValue ? 1 : 0)
    }
    if (bValue < aValue) {
        return -1
    }
    if (bValue > aValue) {
        return 1
    }
    return 0
}
