export const randomObjectId = function () {
  let randHex = function (len: any) {
    let maxlen = 8,
      min = Math.pow(16, Math.min(len, maxlen) - 1),
      max = Math.pow(16, Math.min(len, maxlen)) - 1,
      n = Math.floor(Math.random() * (max - min + 1)) + min,
      r = n.toString(16);

    while (r.length < len) {
      r = r + randHex(len - maxlen);
    }
    return r;
  };

  return randHex(24);
};

export const formatFileName = (filename: string) => {
  return filename ? filename?.split('.')?.slice(0, -1)?.join('.') : '';
};


// export const city_options = cities.map((i) => ({
//   label: i.HebrewCity,
//   value: i.HebrewCity,
// }));

// export const ticket_action_options = [
//   { label: 'הקובץ dsb לא תקין, נא להעלות שוב', value: 're_open_revision' },
//   { label: 'התחל הרצה שוב', value: 're_process_revision' },
//   { label: 'קריאה טופלה בהצלחה', value: 'close_as_fixed' },
//   { label: 'קריאה טופלה עם שגיאה למשתמש', value: 'close_as_failed' },
// ];

export const ticket_action_options = [
  { label: 'REOPEN_REVISION', value: 're_open_revision' },
  { label: 'RE_PROCESS_REVISION', value: 're_process_revision' },
  { label: 'CLOSED_FXED', value: 'close_as_fixed' },
  { label: 'CLOSED_FAILED', value: 'close_as_failed' },
];



export function formatClimat(climateValue: any, fullObject: boolean = false) {
  let found: any = [...climate_options, ...climate_v3_options].find(
    (element) => element.value === climateValue
  );
  return fullObject ? found : found.label;
}

export const version_options = [
  { label: 'PROJECT_VERSION_V2', value: '2.x.x' },
  { label: 'PROJECT_VERSION_V3', value: '3.x.x' },
 
];


export const climate_options = [
  { label: 'CLIMATE_A', value: 'A' },
  { label: 'CLIMATE_B', value: 'B' },
  { label: 'CLIMATE_C', value: 'C' },
  { label: 'CLIMATE_D', value: 'D' },
];


export const climate_v3_options = [
  { label: 'ת״א שדה דב', value: 'A1' },
  { label: 'חיפה', value: 'A2' },
  { label: 'מחניים', value: 'B1' },
  { label: 'באר שבע', value: 'B2' },
  { label: 'בית דגן', value: 'B3' },
  { label: 'צפת-הר כנען', value: 'C1' },
  { label: 'ירושלים', value: 'C2' },
  { label: 'אילת', value: 'D1' },
]


export const construction_options = [
  { label: 'TYPE_CONSTRUCTION_NEW', value: 'new'},
  { label: 'TYPE_CONSTRUCTION_EXIST', value: 'existing'}
]

export const terrain_v3_options = [
  { label: 'TERRAIN_OPTION_CITY', value: 'city' },
  { label: 'TERRAIN_OPTION_SUBURBS', value: 'suburbs' },
  { label: 'TERRAIN_OPTION_COUNTRY', value: 'country' },
];


export const type_options = [
  { label: 'TYPE_RESIDENTIAL', value: 'Residential' },
  { label: 'TYPE_OFFICE', value: 'Office' },
  { label: 'TYPE_OFFICE_CORE_SHELL', value: 'Office Core & Shell' },
  { label: 'TYPE_EDUCATIONAL', value: 'Educational' },
  { label: 'TYPE_HOTEL', value: 'Hotel' },
];

export const subject_options = [
  { label: 'תמיכה', value: 'support' },
  { label: 'הדרכה', value: 'training' },
  { label: 'המלצה', value: 'ask' },
];

export function formatType(typeValue: any, fullObject: boolean = false) {
  let found: any = type_options.find((element) => element.value === typeValue);
  return fullObject ? found : found.label;
}

export const user_authorizations = [
  { label: 'BUSINESS_SETTING_MEMBER_PERMISSION_MEMBER', value: 'member'},
  { label: 'BUSINESS_SETTING_MEMBER_PERMISSION_MANAGER', value: 'manager'},
  { label: 'BUSINESS_SETTING_MEMBER_PERMISSION_VIEWER', value: 'viewer' }
]

export const package_options = [
  { label: 'PACKAGE_CUSTOM', value: 'CUSTOM' },
  { label: 'PACKAGE_TRIAL_30', value: 'TRIAL_30' },
  { label: 'PACKAGE_SINGLE_CREDITS', value: 'SINGLE_CREDITS' },
  { label: 'PACKAGE_TRIAL_UNLIMITED', value: 'TRIAL_UNLIMITED' },
  { label: 'PACKAGE_SMALL_TOPUP', value: 'SMALL_TOPUP' },
  { label: 'PACKAGE_MEDIUM_TOPUP', value: 'MEDIUM_TOPUP' },
  { label: 'PACKAGE_LARGE_TOPUP', value: 'LARGE_TOPUP' },
];

export const colorSet = [
	{ key: 'A+', color: '#3F72B9' },
	{ key: 'A', color: '#10B3DF' },
	{ key: 'B', color: '#0D7A3B' },
	{ key: 'C', color: '#4FB947' },
	{ key: 'D', color: '#FDC21F' },
	{ key: 'E', color: '#F15025' },
	{ key: 'F', color: '#B92126' },
];


// export function getText(code) {
//   let text = localization[code];
//   return text || code;
// }

// export function formatAuthorization(authorizationValue: any) {
//   const list = [
//     { label: 'עובד', value: 'member' },
//     { label: 'מנהל', value: 'manager' },
//     { label: 'אורח', value: 'viewer' },
//   ];
//   let found = list.find((element) => element.value === authorizationValue);
//   return found.label;
// }

// export function ellipsisAndToolTip(text: any, maxLength: any) {
//   return text && text.length > maxLength ? (
//     <span title={text}>{ellipsis(text, maxLength)}</span>
//   ) : (
//     <span>{text || ''}</span>
//   );
// }
