import { TableCell, TableCellProps } from '@mui/material';
import React from 'react';

interface CustomTableCellProps extends TableCellProps {
  children?: React.ReactNode;
  disabled?: boolean;
  collapseMode?:boolean;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({ children,collapseMode, disabled = false, ...props }) => {
  return (
    <TableCell
      style={{
        height: '54px',
        maxHeight:"54px",
        padding: '0 15px', 
        ...(collapseMode && { paddingBottom: 0, 
        paddingTop: 0,borderBottom: 'none'}),
        ...(disabled && { color: 'rgba(0, 0, 0, 0.38)' }),
        ...props.style
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;