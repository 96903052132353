import RemoveIcon from '@mui/icons-material/Remove';
import { TableRow } from '@mui/material';
import CustomTableCell from 'components/CustomTableCell';
import moment from 'moment';

const DebitTransaction = ({ transaction }: any) => {
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      // key={key}
    >
      <CustomTableCell  sx={{width:"20px"}} align='center' component='th' scope='row' >
        <RemoveIcon />
      </CustomTableCell>
      <CustomTableCell sx={{width:"180px"}}>
        {moment.utc(transaction.createdAt).local().format('DD/MM/YY')}{' '}
        <span className='table-time'>
          {moment.utc(transaction.createdAt).local().format('HH:mm')}
        </span>
      </CustomTableCell>
      <CustomTableCell>{transaction.user ?? ''}</CustomTableCell>
      <CustomTableCell>{transaction.project ?? ''}</CustomTableCell>
      <CustomTableCell align='right'>{transaction.points ?? ''}</CustomTableCell>
    </TableRow>
  );
};

export default DebitTransaction;
